import { ComponentSingleStyleConfig, DeepPartial, Theme } from '@chakra-ui/react';

const Radio: DeepPartial<Theme['components']['Radio']> & ComponentSingleStyleConfig = {
  baseStyle: (props) => ({
    container: {
      _checked: {
        bg: `${props.colorScheme}.100`,
      },
    },
    label: {
      ml: '3'
    },
    control: {
      bg: 'white',
      borderColor: 'gray.400',
      _checked: {
        _before: {
          bg: 'transparent'
        },
      },
      _focus: {
        '--chakra-shadows-outline-color': `colors.${props.colorScheme}.300`,
        '--chakra-shadows-outline': `0 0 0 3px var(--chakra-shadows-outline-color)`,
      },
      _invalid: {
        _focus: {
          '--chakra-shadows-outline-color': `colors.red.300`,
        }
      }
    },
  }),
  sizes: {
    xl: {
      control: { w: 6, h: 6 },
      label: { fontSize: 'lg' },
    },
  },
  defaultProps: {
    size: 'xl',
    colorScheme: 'brand'
  },
};

export default Radio;
