import { ComponentSingleStyleConfig, DeepPartial, theme, Theme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const Button: DeepPartial<Theme['components']['Button']> & ComponentSingleStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    fontWeight: 'semibold',
    'svg': {
      margin: '0 -4px',
    },
  },
  sizes: {
    sm: {
      height: '7',
    },
    md: {
      height: '9',
    },
  },
  variants: {
    jumbo: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants?.solid(props),
      bg: 'white',
      fontWeight: 'bold',
      justifyContent: 'left',
      textAlign: 'left',
      whiteSpace: 'normal',
      fontSize: ['2xl', '3xl'],
      borderRadius: '2xl',
      height: '100%',
      padding: '5',
      _before: {
        content: '""',
        paddingBottom: '100%',
        display: 'block',
      },
      _focus: {
        '--chakra-shadows-outline-color': `colors.${props.colorScheme}.600`,
        '--chakra-shadows-outline': `0 0 0 3px var(--chakra-shadows-outline-color)`,
      }
    }),
    border: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants?.outline(props),
      _focus: {
        '--chakra-shadows-outline-color': `colors.${props.colorScheme}.600`,
        '--chakra-shadows-outline': `0 0 0 3px var(--chakra-shadows-outline-color)`,
      },
    }),
    outline: (props: StyleFunctionProps) => ({
      bg: 'white',
      border: 'white',
      _focus: {
        '--chakra-shadows-outline-color': `colors.${props.colorScheme}.600`,
        '--chakra-shadows-outline': `0 0 0 3px var(--chakra-shadows-outline-color)`,
      },
    }),
    solid: (props: StyleFunctionProps) => ({
      _focus: {
        '--chakra-shadows-outline-color': `colors.${props.colorScheme}.600`,
        '--chakra-shadows-outline': `0 0 0 3px var(--chakra-shadows-outline-color)`,
      },
    }),
    link: (props: StyleFunctionProps) => ({
      _focus: {
        '--chakra-shadows-outline-color': `colors.${props.colorScheme}.600`,
        '--chakra-shadows-outline': `0 0 0 3px var(--chakra-shadows-outline-color)`,
      },
    }),
    ghost: (props: StyleFunctionProps) => ({
      _focus: {
        '--chakra-shadows-outline-color': `colors.${props.colorScheme}.200`,
        '--chakra-shadows-outline': `0 0 0 3px var(--chakra-shadows-outline-color)`,
      },
    }),
  },
  defaultProps: {
    colorScheme: 'brand',
  }
};

export default Button;
