import { createContext, PropsWithChildren, useContext } from 'react';
import { get } from 'lodash-es';
import ReactHtmlParser from 'react-html-parser';

interface I18nProviderContextProps {
  t: (key: string, data?: any) => string;
  locale: string;
  translations: any;
  i18n: {
    exists: (key: | string) => boolean
  }
}

type Props = {
  translations: object;
  locale: string;
};

export const I18nContext = createContext<I18nProviderContextProps>(null!);

export const I18nProvider = ({ translations = {}, locale, children }: PropsWithChildren<Props>) => {
  const cache = {};

  const t = (key, data = {}) => {
    const encodedData = btoa(toBinaryStr(JSON.stringify(data)));
    const cacheKey = `${locale}_${key}_${encodedData}`;

    if (cache[cacheKey]) {
      const result = cache[cacheKey];

      return result.length > 1 ? result : result[0] as string;
    }

    let text = get(translations, key) as string ?? '';
    const tokens = text.matchAll(/:(\w+)/g);

    for (const token of tokens) {
      const key = data[token[1]];

      if (key) {
        text = text.replaceAll(token[0], key);
      }
    }

    const result = ReactHtmlParser(text);

    cache[cacheKey] = result;

    // Parsing with "ReactHtmlParser" results in an array of strings, when the translated text has no HTML elements
    // just return as a plain text, due to use in validation messages, which doesn't support an array of strings.
    return result.length > 1 ? result : result[0] as string;
  };

  const i18n = {
    exists: (key) => {
      return !!get(translations, key);
    }
  };

  return <I18nContext.Provider value={{ t, translations, locale, i18n }}>
    { children }
  </I18nContext.Provider>
};

export function useI18n() {
  const context = useContext(I18nContext);

  if (!context) {
    throw new Error(
      'useI18n hook was called outside of I18nProvider context'
    );
  }

  return context;
}


function toBinaryStr(str) {
  const encoder = new TextEncoder();
  const charCodes = encoder.encode(str);
  return String.fromCharCode(...charCodes);
}
