import { ComponentSingleStyleConfig, DeepPartial, Theme } from '@chakra-ui/react';

const Form: DeepPartial<Theme['components']['Form']> & ComponentSingleStyleConfig = {
  baseStyle: {
    container: {
      mb: 4,
    },
    helperText: {
      fontSize: '13px',
    },
  }
};

export default Form;
