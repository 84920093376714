import { ComponentSingleStyleConfig, DeepPartial, Theme } from '@chakra-ui/react';

const Alert: DeepPartial<Theme['components']['Alert']> & ComponentSingleStyleConfig = {
    baseStyle: {
        description: {
            'a': {
                textDecoration: 'underline',
                color: 'blue.500'
            }
        }
    }
};

export default Alert;
