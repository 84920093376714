import { DeepPartial, theme, Theme } from '@chakra-ui/react';

const styles: DeepPartial<Theme['styles']> = {
  global: {
    '.raw-html': {
      'h1, h2, h3, h4, h5, h6': {
        ...theme.components.Heading.baseStyle
      },
      h1: { ...theme.components.Heading.sizes['4xl'] },
      h2: { ...theme.components.Heading.sizes['xl'] },
      h3: { ...theme.components.Heading.sizes['lg'] },
      h4: { ...theme.components.Heading.sizes['md'] },
      h5: { ...theme.components.Heading.sizes['sm'] },
      h6: { ...theme.components.Heading.sizes['xs'] },
      p: {
        fontSize: 'md',
        mt: '3'
      },
      'a img': {
        display: 'inline',
      }
    },
  }
};

export default styles;
