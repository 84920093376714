import { ComponentSingleStyleConfig, DeepPartial, theme, Theme } from '@chakra-ui/react';

const Heading: DeepPartial<Theme['components']['Heading']> & ComponentSingleStyleConfig = {
  baseStyle: {
    fontWeight: 'semibold'
  },
  variants: {
    mono: {
      fontFamily: 'IBM Plex Mono',
      letterSpacing: theme.letterSpacings.widest,
      fontWeight: 'bold',
    }
  },
};

export default Heading;
