import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

export interface RequestType {
  id: number;
  type: string;
  translations: {
    en: string;
    es: string;
    fr: string;
  };
  settings: {
    refundable_type?: string;
  };
  is_active: boolean;
}

export interface MachineTypes {
  id: number;
  type: string;
  translations: {
    en: string;
    es: string;
    fr: string;
  };
}

export interface OwnerSettings {
  'content_url_about-us'?: string;
  'content_url_terms-of-use'?: string;
  'content_url_privacy-policy'?: string;
  'content_tag_details-format': string;
  'content_tag_details-hidden': boolean;
  'content_customer_show-blocked-message': boolean;
  feedback_disabled: boolean;
  feedback_email_subject: string;
  feedback_email_message: string;
  feedback_review_url: string;
  feedback_review_threshold: number;
  feedback_invitations_delay: number;
  feedback_invitations_active: boolean;
  feedback_external_active: boolean;
  refunds_active: boolean;
  'refunds_max-amount': boolean;
  'refunds_has-pre-auth-enabled': boolean;
  'machine-location_field_active': boolean;
  customers_confirmation_active: boolean;
  notification_customer_channels: ['email' | 'sms'];
}

export interface RefundMethod {
  id: number;
  name?: string;
  type: string;
  is_active: boolean;
  settings: {
    vendorcard_maxlength: number;
    'vendorcard_account-identifier': 'CardNumber' | 'Email' | 'PhoneNumber';
  };
  translations?: {
    name: {
      en?: string;
      es?: string;
      fr?: string;
    }
  };
}

export interface PaymentMethod {
  id: number;
  name?: string;
  type: string;
  pre_auth_refund_method_type: 'Refund' | 'Reversal';
  pre_auth_follow_up_delay: number;
  pre_auth_enabled: boolean;
  is_active: boolean;
  settings: {
    vendorcard_maxlength: number;
    'vendorcard_account-identifier': 'CardNumber' | 'Email' | 'PhoneNumber';
    'collect_all_refund-details': boolean;
  };
  translations?: {
    name: {
      en?: string;
      es?: string;
      fr?: string;
    }
  };
}

export interface Owner {
  name: string;
  phone_number: string;
  logo_path: string;
  theme_color: string;
  country_code: string | any;
  company_url_slug: string;
  about_us: string;
  term_of_use: string;
  private_policy: string;
  translations: object;
  supported_languages: string[];
  require_machine_id_title_flg: boolean,
  require_machine_id_in_refund_flg: boolean,
  require_description_flg: boolean,
  require_image_flg: boolean,
  require_photo_in_refund_flg: boolean,
  request_types: RequestType[],
  machine_types: MachineTypes[],
  settings: OwnerSettings;
  payment_methods: PaymentMethod[],
  refund_methods: RefundMethod[],
}

export interface Tag {
  id: number;
  tag_type: {
    type: string;
  };
  name: string;
  account_name: string;
  location_id: string;
  location_name: string;
  asset_id: string | number;
  identifier: string | number;
  description: string;
  address: {
    name: string;
    geometry: {
      location: {
        lat: number
        lng: number;
      };
    };
    place_id: string;
    business_status: string;
    formatted_address: string;
    address_components: {
      types: string[];
      long_name: string;
      short_name: string;
    }[];
  };
  meta: {
    refunds_disabled: boolean;
    pre_auth_disabled: boolean;
    allowed_refund_methods: string[];
    allowed_payment_methods: string[];
    allowed_request_types: string[];
    photo_requirement?: boolean;
  }
}

export interface Customer {
  id: number;
  name: string;
  email: string;
  phone_number: string;
}

export interface Token {
  id: string;
  phone_number: string;
  tag: Tag;
  customer: Customer;
}

export interface RequestLink {
  id: string;
  type: 'Help' | 'Refund' | 'Feedback';
  skip_pre_auth: boolean;
  tag?: Tag;
  customer: Customer;
  request?: {
    refund_id: number;
    help_id: number;
  }
}

export interface Prefill {
  phone_number?: string;
  tag?: Tag | null;
  customer?: Customer;
}

export interface RefundMethodOptions {
  label: string;
  value: string;
  translations?: {
    name: {
      en?: string;
      es?: string;
      fr?: string;
    };
    description: {
      en?: string;
      es?: string;
      fr?: string;
    };
  };
  is_active: boolean;
}

export interface Refund {
  reference: string;
  amount: number;
  days_since_purchase: number;
  created_at: string;
  customer_name: string;
  payment_method: PaymentMethod;
  tag?: Tag;
}

export interface PageData {
  owner: Owner;
  token?: Token;
  requestLink?: RequestLink;
  prefill: Prefill;
  refund: Refund;
  settings: OwnerSettings;
  refundMethods: RefundMethodOptions[];
}

interface Prefill {
  phone_number?: string;
  customer?: Customer;
  tag?: Tag | null;
}

interface PageDataProviderContextProps extends PageData {
  setData: (data: Partial<PageData>) => void;
  setPrefill: (data: Prefill) => void;
}

type Props = {
  defaultData: PageData;
};

export const PageDataContext = createContext<PageDataProviderContextProps>(null!);

export const PageDataProvider = ({ defaultData, children }: PropsWithChildren<Props>) => {
  const [pageData, setPageData] = useState<PageData>(defaultData);

  const [prefill, setPrefill] = useState<Prefill>({
    phone_number: pageData.token?.phone_number ?? pageData.requestLink?.customer?.phone_number,
    customer: pageData.token?.customer ?? pageData.requestLink?.customer,
    tag: pageData.token?.tag ?? pageData.requestLink?.tag,
  });

  const updatePrefill = (data: Prefill) => {
    setPrefill({ ...prefill, ...data });
  };

  return <PageDataContext.Provider value={{
    owner: pageData.owner,
    token: pageData.token,
    requestLink: pageData.requestLink,
    setPrefill: updatePrefill, prefill,
    refund: pageData?.refund || {} as Refund,
    settings: pageData.settings,
    refundMethods: pageData.refundMethods,
    setData: (data) => {
      return setPageData({ ...pageData, ...data });
    },
  }}>
    { children }
  </PageDataContext.Provider>
};

export function usePageData() {
  const context = useContext(PageDataContext);

  if (!context) {
    throw new Error(
      'usePageData hook was called outside of PageDataProvider context'
    );
  }

  return context;
}
