import { ComponentSingleStyleConfig, DeepPartial, Theme } from '@chakra-ui/react';

const FormLabel: DeepPartial<Theme['components']['FormLabel']> & ComponentSingleStyleConfig = {
  baseStyle: {
    marginInlineEnd: 'unset',
    '.chakra-form__error-message': {
      marginTop: 0,
    },
    _disabled: {
      opacity: 1,
    },
  },
  variants: {
    heavy: {
      fontSize: 'lg',
      fontWeight: 'semibold',
      mb: '4'
    },
  }
};

export default FormLabel;
