import { ComponentSingleStyleConfig, DeepPartial, Theme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const Input: DeepPartial<Theme['components']['Input']> & ComponentSingleStyleConfig = {
  variants: {
    outline: (props: StyleFunctionProps) => ({
      field: {
        bg: 'white',
        _disabled: {
          opacity: 1,
          color: 'gray.500',
          bg: 'gray.200'
        },
      },
    }),
  },
  defaultProps: {
    focusBorderColor: 'black',
  },
};

export default Input;
