import { ComponentSingleStyleConfig, DeepPartial, Theme } from '@chakra-ui/react';

const FormError: DeepPartial<Theme['components']['FormError']> & ComponentSingleStyleConfig = {
  baseStyle: {
    text: {
      fontSize: '13px',
      lineHeight: 'normal',
    },
  },
}

export default FormError;
