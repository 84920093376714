import { DeepPartial, Theme } from '@chakra-ui/react';

/** extend additional color here */
const extendedColors: DeepPartial<
  Record<string, Theme['colors']['blackAlpha']>
> = {
  brand: {
    50: 'var(--zippy-colors-brand-50)',
    100: 'var(--zippy-colors-brand-100)',
    200: 'var(--zippy-colors-brand-200)',
    300: 'var(--zippy-colors-brand-300)',
    400: 'var(--zippy-colors-brand-400)',
    500: 'var(--zippy-colors-brand-500)',
    600: 'var(--zippy-colors-brand-600)',
    700: 'var(--zippy-colors-brand-700)',
    800: 'var(--zippy-colors-brand-800)',
    900: 'var(--zippy-colors-brand-900)',
  },
};

/** override chakra colors here */
const overriddenChakraColors: DeepPartial<Theme['colors']> = {};

const colors = {
  ...overriddenChakraColors,
  ...extendedColors,
};

export default colors;
