import { extendTheme } from '@chakra-ui/react';

import config from './config';
import styles from './styles';
import fonts from './fonts';
import colors from './colors';
import Accordion from './components/accordion';
import Alert from './components/alert';
import Button from './components/button';
import Heading from './components/heading';
import FormLabel from './components/form-label';
import FormError from './components/form-error';
import Form from './components/form';
import Input from './components/input';
import Textarea from './components/textarea';
import Link from './components/link';
import Radio from './components/radio';

const customTheme = extendTheme({
  config,
  styles,
  fonts,
  colors,
  components: {
    Accordion,
    Alert,
    Button,
    Heading,
    FormLabel,
    FormError,
    Form,
    Input,
    Textarea,
    Link,
    Radio
  },
});

export default customTheme;
