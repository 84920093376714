import { usePageData } from 'lib/hooks/PageDataContext';
import { Box, Heading } from '@chakra-ui/react';

const Header = () => {
  const { owner } = usePageData();

  if (!owner) {
    return <></>;
  }

  return (
    <Box display="flex" justifyContent="center">
      {owner.logo_path && <img src={owner.logo_path} style={{ maxHeight: 85, maxWidth: 220, minHeight: 45, objectFit: 'contain' }} />}
      {!owner.logo_path && <Heading color="brand.500"  size="2xl" fontWeight="extrabold">{owner.name}</Heading>}
    </Box>
  );
};

export default Header;
