import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter';

// const cache = setupCache({
//   maxAge: 30 * 1000
// });

export default axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
  // adapter: cache.adapter,
  withCredentials: true,
});
