import { ReactNode } from 'react';
import { Box, Container } from '@chakra-ui/react';
import FocusLock from 'react-focus-lock';

import Header from './Header';

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return <>
    <Container maxW="container.sm" px="4" py="6">
      <Header />

      <Box as="main" mt="8" mb="10" pos="relative">
        <FocusLock autoFocus={false}>{children}</FocusLock>
      </Box>
    </Container>
  </>;
};

export default Layout;
