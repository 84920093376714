import { ComponentSingleStyleConfig, DeepPartial, Theme } from '@chakra-ui/react';

const Accordion: DeepPartial<Theme['components']['Accordion']> & ComponentSingleStyleConfig = {
    baseStyle: {
        container: {
            _focusWithin: {
                boxShadow: 'outline',
            },
            _first: {
                borderTop: 0,
            }      
        },
        button: {
            px: 3,
            _focus: {
                boxShadow: 'none',
            },
            _hover: {
                bg: 'unset'
            }
        },
        panel: {
            pt: 0,
            px: 3,
            pb: 3,
        },
    }
};

export default Accordion;
